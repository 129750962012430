import React, { useRef } from 'react';
import './Blog.css';
import hammer from './assets/hammer.png';

function Blog() {
  return (
    <div className="not-found">
    <img className="hammer" src={hammer}/>
      <h1>Oops!</h1>
      <p>Parece que essa area do website ainda está sendo trabalhada!.</p>
      <a href="/">Clique aqui para voltar</a>
    </div>
  );
}

export default Blog;
