import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, NavLink } from 'react-router-dom';
import Portfolio from './components/pt/Portfolio';
import Projects from './components/pt/Projects';
import Contact from './components/pt/Contacts';
import English from './components/en/En.js';
import EnContact from './components/en/ContactEN.js';
import EnPortfolio from './components/en/PortfolioEn.js';
import EnProjects from './components/en/ProjectsEn.js';
import Blog from './components/pt/blog/Blog.js'
import Home from './components/pt/Home.js';
import usa from './components/pt/assets/usa.png'
import br from './components/pt/assets/brazil.png'
import Fade from 'react-reveal/Fade';
import Bounce from "react-reveal";
import NotFound from './Error404'; // Importando o componente de erro 404




function Navigation({ toggleMenu, toggleTheme, isDarkMode, menuOpen }) {
  const location = useLocation();
  const isEnglish = location.pathname.startsWith('/en');

  const [fontIndex, setFontIndex] = useState(0);
  const fontSizes = {'Inter-Bureau': '35px', 'Gang of Three': '26px', 'Danny Varefella': '30px', 
                    'Retro Land Mayhem': '20px', 'Wolfsburg': '25px', 'ENOCH': '20px', 'Year One': '20px', 'mister bambu': '20px'}; // Lista de fontes que você quer alternar

  const [currentFont, setCurrentFont] = useState('Danny Varefella'); // Fonte inicial
  const [currentSize, setCurrentSize] = useState(fontSizes['Danny Varefella']); // Tamanho inicial

  const fonts = Object.keys(fontSizes); // Obtenha a lista de fontes

  useEffect(() => {
    const interval = setInterval(() => {
      // Altera a fonte e o tamanho a cada 3 segundos
      setCurrentFont(prevFont => {
        const currentIndex = fonts.indexOf(prevFont);
        const nextIndex = (currentIndex + 1) % fonts.length;
        const nextFont = fonts[nextIndex];
        setCurrentSize(fontSizes[nextFont]); // Atualiza o tamanho da fonte
        return nextFont;
      });
    }, 3000); // Altere o tempo conforme desejado

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [fonts]);


  const [emojiTransition, setEmojiTransition] = useState(false);

  const handleToggleTheme = () => {
    setEmojiTransition(true);
    toggleTheme();
    setTimeout(() => {
      setEmojiTransition(false);
    }, 500); // Tempo igual ao da transição CSS
  };
  

  return (
    <div className="navbar">
        
      <Bounce top delay={10}>
      <a className="logo" href="/" style={{ fontFamily: currentFont, fontSize: currentSize }}>Lass DEV</a>
      </Bounce >
      <button className={`menu-toggle ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span></span><span></span><span></span>
      </button>
      <Bounce top cascade damping={5} >
      <ul className={`menu ${menuOpen ? 'active' : ''}`}>
        <li>
          <a href={isEnglish ? "/en/" : "/pt/"} className={location.pathname === (isEnglish ? "/en/" : "/pt/") ? "active" : ""}>
            {isEnglish ? "About" : "Sobre"}
          </a>
        </li>
        <li>
          <a href={isEnglish ? "/en/portfolio" : "/pt/portfolio"} className={location.pathname === (isEnglish ? "/en/portfolio" : "/pt/portfolio") ? "active" : ""}>
            {isEnglish ? "Portfolio" : "Portfolio"}
          </a>
        </li>
        <li>
          <a href={isEnglish ? "/en/projects" : "/pt/projects"} className={location.pathname === (isEnglish ? "/en/projects" : "/pt/projects") ? "active" : ""}>
            {isEnglish ? "Projects" : "Projetos"}
          </a>
        </li>
        <li>
          <a href={isEnglish ? "/en/contact" : "/pt/contact"} className={location.pathname === (isEnglish ? "/en/contact" : "/pt/contact") ? "active" : ""}>
            {isEnglish ? "Contact" : "Contato"}
          </a>
        </li>
      </ul>
      </Bounce >
      <ul className="menu2">
        <li>
          <a href={isEnglish ? "/pt/" : "/en/"} className="language-toggle"> {/* Adiciona uma nova classe aqui */}
            <img
              src={isEnglish ? usa : br}
              alt={isEnglish ? 'USA Flag' : 'Bandeira do Brasil'}
              style={{ width: '24px', height: '24px', marginLeft: '5px' }} 
            />
          </a>
        </li>

        <button className={`theme-toggle ${emojiTransition ? 'emoji-transition' : ''}`} onClick={handleToggleTheme}>
          {isDarkMode ? '☀️' : '🌙'}
        </button>
      </ul>

    </div>
  );
}


function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem('theme') === 'dark' || !localStorage.getItem('theme');
  });
  const [showScrollIndicator, setShowScrollIndicator] = useState(true); // Estado para controlar a visibilidade da seta

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleTheme = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('theme', newMode ? 'dark' : 'light');
      return newMode;
    });
  };

  useEffect(() => {
    document.body.classList.toggle('dark', isDarkMode);
    document.body.classList.toggle('light', !isDarkMode);
  }, [isDarkMode]);

  // Adicionando o evento de scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY; // Posição atual do scroll
      const windowHeight = window.innerHeight; // Altura da janela
      const documentHeight = document.documentElement.scrollHeight; // Altura total do documento

      // Verifica se chegou ao final da página
      if (scrollTop + windowHeight >= documentHeight) {
        setShowScrollIndicator(false);
      } else {
        setShowScrollIndicator(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Fade>
          <header className="site-header">
            <Navigation 
              toggleMenu={toggleMenu} 
              toggleTheme={toggleTheme} 
              isDarkMode={isDarkMode} 
              menuOpen={menuOpen} 
            />
          </header>
        </Fade>

        <Routes>
          <Route path="/" element={<Navigate to="/pt/" />} />
          <Route path="/pt/" element={<Home />} />
          <Route path="/pt/portfolio" element={<Portfolio />} />
          <Route path="/pt/projects" element={<Projects />} />
          <Route path="/pt/contact" element={<Contact />} />
          <Route path="/pt/blog" element={<Blog />} />
          <Route path="/en/" element={<English />} />
          <Route path="/en/contact" element={<EnContact />} />
          <Route path="/en/portfolio" element={<EnPortfolio />} />
          <Route path="/en/projects" element={<EnProjects />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        
        {showScrollIndicator && ( // Renderiza a seta apenas se showScrollIndicator for true
          <div className="scroll-indicator">
            <span>▼</span>
          </div>
        )}

        <footer>
          <p>© Gustavo Lass [2024]</p>
          <p>Made in ReactJS by Gustavo Lass</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
