import { useRef, useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import Laptop from './Laptop'; // Ajuste o caminho conforme necessário

function LaptopWrapper() {
    const ref = useRef();
    const mouse = useRef({ x: 0, y: 0 });
    const [rotationX, setRotationX] = useState(-Math.PI / 4); // Começa em -45 graus

    useEffect(() => {
        const handleMouseMove = (event) => {
            // Normaliza a posição do mouse para um intervalo de -1 a 1
            mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const animateRotation = () => {
            // Suaviza a rotação com base na posição do mouse
            const smoothingFactor = 0.1; // Ajuste aqui conforme necessário
            setRotationX((prev) => {
                const targetRotationX = mouse.current.y * (Math.PI / 4); // Define o alvo com base na posição do mouse
                const newRotationX = prev + (targetRotationX - prev) * smoothingFactor;

                // Para a animação se a rotação estiver suficientemente próxima do alvo
                return Math.abs(newRotationX - targetRotationX) < 0.001 ? targetRotationX : newRotationX;
            });
            requestAnimationFrame(animateRotation); // Chama novamente a função
        };
        animateRotation(); // Inicia a animação
    }, []); // Mantém a dependência para não reiniciar

    useFrame(() => {
        if (ref.current) {
            // Atualiza a rotação do laptop no eixo X
            ref.current.rotation.x = -rotationX; // Usa a rotação animada
            ref.current.rotation.y = mouse.current.x * (Math.PI / 4); // Rotação no eixo Y baseado na posição do mouse
            ref.current.rotation.z = 0; // Garante que a rotação no eixo Z permaneça em 0
        }
    });

    return (
        <mesh ref={ref}>
            <Laptop />
        </mesh>
    );
}

export default LaptopWrapper;
