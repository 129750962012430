import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

// Array com os dados dos projetos, incluindo os caminhos das imagens
const projectData = [
  { id: 1, title: "Unity small game project", link: "/en/blog", image: require('./assets/game.gif') },
  { id: 2, title: "Kernel Cheats", link: "/en/blog", image: require('./assets/valorant.mp4') },
  { id: 3, title: "Custom Linktree Discord", link: "/en/blog", image: require('./assets/dc.png') },
  { id: 4, title: "My projects (Github)", link: "https://github.com/D4rkks", image: require('./assets/ghub.png') },
  // Adicione mais projetos conforme necessário
];

const Projects = () => {
  const projectsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(projectData.length / projectsPerPage);

  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  // Seleciona os projetos para a página atual
  const currentProjects = projectData.slice(
    (currentPage - 1) * projectsPerPage,
    currentPage * projectsPerPage
  );

  return (
    <main className="Projects">
      <Fade>
        <div className="proj-title">
          <h1>PROJECTS</h1>
          <h2>Personal projects, and others</h2>
        </div>
      </Fade>
      <div className="project-grid">
        {currentProjects.map((project) => (
          <Link
            to={project.link}
            className="proj-subtitle"
            key={project.id}
          >
            {project.image.includes('.mp4') ? (
              <video
                autoPlay
                loop
                muted
                className="proj-video"
              >
                <source src={project.image} type="video/mp4" />
              </video>
            ) : (
              <div
                className="proj-background"
                style={{
                  backgroundImage: `url(${project.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  zIndex: -1,
                }}
              ></div>
            )}
            <span className="proj-text">{project.title}</span>
          </Link>
        ))}
      </div>
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1} className="pagination-btn">
          ←
        </button>
        <span> {currentPage} out of {totalPages}</span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages} className="pagination-btn">
          →
        </button>
      </div>
    </main>
  );
};

export default Projects;
