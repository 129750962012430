// Home.js
import will from './assets/will.webp';
import cf from './assets/cf.png';
import discord from './assets/discord.png';
import git from './assets/git.png';
import instagram from './assets/instagram.png';
import telegram from './assets/telegram.png';
import horn from './assets/mlg.mp3';
import { useState, useRef, useEffect, Suspense } from 'react';
import Zoom from 'react-reveal/Zoom'; 
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Reveal from 'react-reveal/Reveal';
import CardSlider from "./CardSlider/CardSlider";
import CardSlider2 from "./CardSlider/CardSlider2";
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Environment, PointLight  } from '@react-three/drei';
import { useGlitch } from 'react-powerglitch'
import { useParallax } from 'react-scroll-parallax';
import LaptopWrapper from '../file/LaptopWrapper'; // Importa o novo componente
import robozao from './assets/robozao.png'
import chara from './assets/chara.png'

function Home() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = useRef(null);
    const slideLabels = ["Development", "Experience", "Skills", "Projects", "Contact"];

    // Ensure the first slide is visible on mount
    useEffect(() => {
        const items = carouselRef.current.children;
        if (items.length > 0) {
            items[0].classList.add('show'); // Add 'show' class to the first item
        }
    }, []);

    const handleScroll = (event) => {
        // Check if the event is real and has preventDefault method
        if (event && typeof event.preventDefault === 'function') {
            event.preventDefault();
        }
    
        const scrollAmount = event.deltaY || 0; // Use event.deltaY or fallback to 0
    
        const itemWidth = carouselRef.current.scrollWidth / carouselRef.current.childElementCount;
        const currentIndex = Math.round(carouselRef.current.scrollLeft / itemWidth);
        const nextIndex = Math.max(0, Math.min(carouselRef.current.childElementCount - 1, currentIndex + Math.sign(scrollAmount)));
    
        setCurrentSlide(nextIndex);
    
        // Add 'show' class to the current item
        const items = carouselRef.current.children;
        for (let i = 0; i < items.length; i++) {
            if (i === nextIndex) {
                items[i].classList.add('show');
            } else {
                items[i].classList.remove('show');
            }
        }
    
        let startTime = null;
        const stickyScrollLeft = nextIndex * itemWidth;
    
        const animateScroll = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const easing = Math.min(elapsed / 2000, 1);
            const easeInOutQuad = easing < 0.5 ? 2 * easing * easing : -1 + (4 - 2 * easing) * easing;
    
            const currentScrollLeft = carouselRef.current.scrollLeft;
            const distance = stickyScrollLeft - currentScrollLeft;
            const step = distance * easeInOutQuad;
    
            carouselRef.current.scrollLeft += step;
    
            if (Math.abs(distance) > 1) {
                requestAnimationFrame(animateScroll);
            } else {
                carouselRef.current.scrollLeft = stickyScrollLeft;
            }
        };
    
        requestAnimationFrame(animateScroll);
    };
    const [startY, setStartY] = useState(0); // Adicionando o estado para Y
    const [isDragging, setIsDragging] = useState(false); // Para controlar se o usuário está arrastando
    const handleTouchStart = (event) => {
        setStartY(event.touches[0].clientY); // Posição inicial do toque
        setIsDragging(true); // Define que está arrastando
    };

    const handleTouchMove = (event) => {
        if (!isDragging) return; // Verifica se está arrastando
    
        const currentY = event.touches[0].clientY;
        const diffY = startY - currentY;
    
        // Verifica a direção do arrasto
        if (diffY > 50) {
            // Arrastou para cima, avance o slide
            handleScroll({ deltaY: 1, preventDefault: () => {} }); // Mocking preventDefault
            setIsDragging(false); // Para evitar múltiplas chamadas
        } else if (diffY < -50) {
            // Arrastou para baixo, retroceda o slide
            handleScroll({ deltaY: -1, preventDefault: () => {} }); // Mocking preventDefault
            setIsDragging(false); // Para evitar múltiplas chamadas
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false); // Finaliza o arrasto
    };

    const glitch = useGlitch();


    return (
        <main className="content">
            
            <div className="title-container">
                <div className="title">
                    
                    <Fade delay={201}>
                    <Canvas camera={{ position: [-1, 1, 3.5], fov: 52 }}>
                        <ambientLight intensity={1} color="#fc5203" />
                        <OrbitControls 
                            enableZoom={false}
                            enablePan={true} // Permite mover a cena
                            minPolarAngle={Math.PI / 4} // Limita a rotação para baixo
                            maxPolarAngle={Math.PI / 2} // Limita a rotação para cima
                            minAzimuthAngle={-Math.PI / 4} // Limita a rotação para a esquerda
                            maxAzimuthAngle={Math.PI / 4} // Limita a rotação para a direita
                        />
                        <Suspense fallback={null}>
                            <LaptopWrapper/> {/* Passa a posição do mouse */}
                        </Suspense>
                        <Environment preset='city' />
                    </Canvas>
                    </Fade>
                    <Fade delay={501} duration={3000}>
                    <label className="name">GUS</label>
                    <label className="name2">TAVO</label>
                    </Fade>
                    <Fade delay={1001} duration={4000}>
                        <label className="name3"><span ref={glitch.ref}>LASS</span></label>

                        <div className="divider"></div>
                        <div className="undertext-div">
                        <label className="undertext">Full-Stack Developer - Bachelor in Computer Science by UDESC. <br /> 17 years, 7 years+ of experience in development</label>
                        </div>
                    </Fade>
                    <Fade delay={501}>
                        <ul className="buttons"> 
                            <li><a target="_blank" href="https://github.com/D4rkks"><img title="Github" className="img" src={git} /></a></li>
                            <li><a target="_blank" href="https://codeforces.com/profile/D4rkks"><img title="Codeforces" className="img" src={cf} /></a></li>
                            <li><a target="_blank" href="https://discord.com/users/247726496435666944/"><img title="Discord" className="img" src={discord} /></a></li>
                            <li><a target="_blank" href="https://www.instagram.com/gustavo.lass/"><img title="Instagram" className="img" src={instagram} /></a></li>
                            <li><a href=""><img title="Telegram" className="img" src={telegram} /></a></li>
                        </ul>
                    </Fade>
                </div>
            </div>


            <div className="skills">
                <div className="carrousel">
                <div className="contentContainer">
                        <label className="title-en">SKILLS</label>
                        <div 
                            className="carousel" 
                            onWheel={handleScroll} 
                            onTouchStart={handleTouchStart} // Adiciona evento de toque
                            onTouchMove={handleTouchMove} // Adiciona evento de movimento de toque
                            onTouchEnd={handleTouchEnd} // Adiciona evento de finalização de toque
                            ref={carouselRef}
                        >
                            <div className="carousel-item show">
                                <label className="titletext">I'm a junior programmer with extensive skills in front-end and back-end development, 
                                    bot creation and software engineering. I have experience in several languages ​​and frameworks, 
                                    Always seeking to expand my knowledge and apply good ordering practices. With a passion for 
                                    solve problems and focused on user experience.</label>
                                <label className="dev01">01</label>
                                <label className="dev">Development</label>
                                <label className="description">My experience as a developer started with developing bots for Discord, 
                                    where I created automatic attendance systems around 2016/2018. 
                                    Since 2017, I have also worked as a freelancer developing front-ends for websites, 
                                    always seeking to meet customer needs. Between 2020 and 2024, 
                                    worked on advanced development of cheats for games, 
                                    applying bypass techniques to anti-cheat systems and acquiring skills in reverse engineering 
                                    and memory manipulation. I am currently a Computer Science student at UDESC, 
                                    where I continue to expand my knowledge in programming and software development.</label>
                                    <div className="robozao">
                                    <div className="robozao-fade"></div>
                                    <img 
                                        src={robozao} 
                                        alt="Im Jobless" 
                                        className="robozao-img" 
                                    />
                                    </div>
                            </div>
                            <div className="carousel-item show">
                            <label className="titletext2">I'm a junior programmer with extensive skills in front-end and back-end development, 
                                    bot creation and software engineering. I have experience in several languages ​​and frameworks, 
                                    Always seeking to expand my knowledge and apply good ordering practices. With a passion for 
                                    solve problems and focused on user experience.</label>
                                <label className="dev02">02</label>
                                <label className="dev2">Experience</label>
                                <label className="description2">My experience mainly relies on proficiency in C++/C, t
                                    Having focused in recent years on low-level development, 
                                    such as creating drivers for embedded systems and systems (Arduino, Kmbox, etc.). 
                                    Furthermore, I had contact with Java to develop plugins for Minecraft. 
                                    I also have experience with AWS and Google Cloud, where I created and maintained a Minecraft server for about a year, being the sole developer responsible. 
                                    This experience gave me an in-depth understanding of server management and application scalability..</label>

                            </div>
                            <div className="carousel-item show">
                            <label className="titletext3">I'm a junior programmer with extensive skills in front-end and back-end development, 
                                    bot creation and software engineering. I have experience in several languages ​​and frameworks, 
                                    Always seeking to expand my knowledge and apply good ordering practices. With a passion for 
                                    solve problems and focused on user experience.</label>
                                <label className="dev03">03</label>
                                <label className="dev3">Skills</label>
                                <label className="description3">I have experience as a solo bot developer for Discord, 
                                    where I created automatic attendance systems between 2016 and 2018. 
                                    Since 2017, I have worked as a freelancer developing front-ends for websites, 
                                    always seeking to meet customer needs. Between 2020 and 2024, 
                                    worked on advanced development of cheats for games, 
                                    applying bypass techniques to anti-cheat systems and acquiring skills in reverse engineering 
                                    and memory manipulation. I am currently a Computer Science student at UDESC, 
                                    where I continue to expand my knowledge in programming and software development.</label>
                                    
                            </div>
                            <div className="carousel-item show">
                            <label className="titletext4">I'm a junior programmer with extensive skills in front-end and back-end development, 
                                    bot creation and software engineering. I have experience in several languages ​​and frameworks, 
                                    Always seeking to expand my knowledge and apply good ordering practices. With a passion for 
                                    solve problems and focused on user experience.</label>
                                <label className="dev04">04</label>
                                <label className="dev4">Projects</label>
                                <label className="description4">Throughout my career, I have worked on several projects. Although many have been lost over time, 
                                    what remained was the experience gained in each of them. I am currently collecting and sharing some of the projects 
                                    most relevant on my GitHub and on this website. Click the button to check</label>
                                    <a href="/pt/projects" class="button" className="projetos-ir">        
                                        <span class="text">PROJECTS</span>
                                        <span class="arrow">→</span>
                                    </a>
                            </div>
                            <div className="carousel-item show">
                            <label className="titletext5">I'm a junior programmer with extensive skills in front-end and back-end development, 
                                    bot creation and software engineering. I have experience in several languages ​​and frameworks, 
                                    Always seeking to expand my knowledge and apply good ordering practices. With a passion for 
                                    solve problems and focused on user experience.</label>
                                <label className="dev05">05</label>
                                <label className="dev5">Contact</label>
                                <label className="description5">To contact us with questions or more information, 
                                please click the button below. I will be willing to answer your questions. Don't hesitate to contact us!</label>
                                    <a href="/pt/contacts" class="button" className="contact-ir">        
                                        <span class="text">CONTACT</span>
                                        <span class="arrow">→</span>
                                    </a>
                            </div>
                        </div>
                        {/* History line */}
                        <label className="indicator">SCROLL →</label>
                        <div className="history-line">
                               {slideLabels.map((label, index) => (
                                    <div 
                                        key={index} 
                                        className={`history-point ${currentSlide === index ? "active" : ""}`}
                                    >
                                    <span className="slide-number">0{index + 1}</span>
                                    <span className="slide-number2">{label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
 
            <div className="experience">

                    <div className="cards">    
                        <CardSlider/>
                        <CardSlider2/>  
                    </div>
                    <label className="title">ABOUT ME</label>
                    <label class="surname">
                        <span>S</span>
                        <span>S</span>
                        <span>A</span>
                        <span>L</span>
                    </label>
                    <img src={chara} className="chara"></img>
                    <div className="chara-fade"></div>
                    <label className="about">I am currently 17 years old and my journey in the development world started around the age of 9, 
                        when I had my first contact with programming through JavaScript. 
                        Since then, my interest in technology and the logic behind programming 
                        it only grew. Over time, I delved deeper into other languages ​​and, nowadays, 
                        my main programming language is C++/C. These languages ​​allow me 
                        work on projects that require greater control over the hardware and system. Currently studying at UDESC CCT.    
                    </label>
  

            </div>

        </main>
    );
}

export default Home;
