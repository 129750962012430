// src/components/NotFound.js

import React, { useRef } from 'react';
import './Error404.css'; // Se você quiser adicionar estilos personalizados
import rock from './components/pt/assets/sus.gif';
import sound from './components/pt/assets/sus.mp3'; // Importe seu arquivo de som
import { useGlitch } from 'react-powerglitch'
function NotFound() {
  const glitch = useGlitch();
  return (
    <div className="not-found">
      <span className="error" ref={glitch.ref}>ERROR!</span>
      <h1>404 - PAGE NOT FOUND!</h1>
      <p>Sorry, this page does not exist, please go back to home.</p>
      <a href="/">Click here to go back to the Home Page</a>
    </div>
  );
}

export default NotFound;
