import React, { useEffect, useRef  } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const CustomCursor = () => {
  const cursorRef = useRef(null);
  const requestRef = useRef();

  useEffect(() => {
      const cursor = cursorRef.current;

      const moveCursor = (e) => {
          if (cursor) {
              // A posição original
              const cursorWidth = cursor.offsetWidth + 32; // Largura atual do cursor
              const cursorHeight = cursor.offsetHeight + 130; // Altura atual do cursor
      
              // Atualiza a posição do cursor
              cursor.style.transform = `translate(${e.clientX - cursorWidth / 2}px, ${e.clientY - cursorHeight / 2}px)`;
            }
      };

      const render = () => {
          moveCursor(mousePosition);
          requestRef.current = requestAnimationFrame(render);
      };

      let mousePosition = { clientX: 0, clientY: 0 };

      const handleMouseMove = (e) => {
          mousePosition = { clientX: e.clientX, clientY: e.clientY };
      };

      const handleMouseEnter = () => cursor.classList.add('expand');
      const handleMouseLeave = () => cursor.classList.remove('expand');

      // Adiciona evento para movimento do mouse
      document.addEventListener('mousemove', handleMouseMove);
      requestRef.current = requestAnimationFrame(render);

      // Adiciona eventos para elementos interativos
      const clickableElements = document.querySelectorAll('a, button, .clickable');
      clickableElements.forEach(element => {
          element.addEventListener('mouseenter', handleMouseEnter);
          element.addEventListener('mouseleave', handleMouseLeave);
      });

      // Limpeza dos eventos
      return () => {
          cancelAnimationFrame(requestRef.current);
          document.removeEventListener('mousemove', handleMouseMove);
          clickableElements.forEach(element => {
              element.removeEventListener('mouseenter', handleMouseEnter);
              element.removeEventListener('mouseleave', handleMouseLeave);
          });
      };
  }, []);

  return <div className="custom-cursor" ref={cursorRef}></div>;
};


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <CustomCursor />
        <App />
    </React.StrictMode>
);

reportWebVitals();
