/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 laptop.gltf 
Author: Vivien Deroche (https://sketchfab.com/blue-odym)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/cyberpunk-laptop-8330c89e8431451db3aa9d15c5836ddf
Title: CyberPunk Laptop
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import { Html } from '@react-three/drei';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/laptop.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0.1, -0.728]} rotation={[-1.735, 0, 0]}>
        <mesh geometry={nodes.Object_19.geometry} material={materials['Material.012']} >

        </mesh>
        <mesh geometry={nodes.Object_20.geometry} material={materials['Material.008']} />
        <mesh geometry={nodes.Object_21.geometry} material={materials['Material.009']} />
        <mesh geometry={nodes.Object_22.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Object_23.geometry} material={materials['Material.018']} />
        <mesh geometry={nodes.Object_24.geometry} material={materials['Material.019']} />
        <mesh geometry={nodes.Object_25.geometry} material={materials['Material.020']} />
        <mesh geometry={nodes.Object_26.geometry} material={materials['Material.014']} />
        <mesh geometry={nodes.Object_27.geometry} material={materials['Material.015']} />
        <mesh geometry={nodes.Object_28.geometry} material={materials['Material.021']} />
        <mesh geometry={nodes.Object_29.geometry} material={materials['Material.022']} />
        <mesh geometry={nodes.Object_30.geometry} material={materials['Material.023']} />
        <mesh geometry={nodes.Object_31.geometry} material={materials['Material.028']} />
        <mesh geometry={nodes.Object_32.geometry} material={materials['Material.030']} />
        <mesh geometry={nodes.Object_33.geometry} material={materials['Material.034']} />
        <mesh geometry={nodes.Object_34.geometry} material={materials['Material.032']} />
        <mesh geometry={nodes.Object_35.geometry} material={materials['Material.033']} />
        <mesh geometry={nodes.Object_36.geometry} material={materials['Material.035']} />
        <mesh geometry={nodes.Object_37.geometry} material={materials['Material.004']} />
        <mesh geometry={nodes.Object_38.geometry} material={materials['Material.007']} />
        <mesh geometry={nodes.Object_39.geometry} material={materials['Material.006']} />
        <mesh geometry={nodes.Object_40.geometry} material={materials['Material.005']} />
        <mesh geometry={nodes.Object_41.geometry} material={materials['Material.013']} />
      </group>
      <mesh geometry={nodes.Object_4.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Object_5.geometry} material={materials['Material.025']} />
      <mesh geometry={nodes.Object_6.geometry} material={materials['Material.002']} />
      <mesh geometry={nodes.Object_7.geometry} material={materials['Material.028']} />
      <mesh geometry={nodes.Object_8.geometry} material={materials['Material.027']} />
      <mesh geometry={nodes.Object_9.geometry} material={materials['Material.029']} />
      <mesh geometry={nodes.Object_10.geometry} material={materials['Material.031']} />
      <mesh geometry={nodes.Object_11.geometry} material={materials['Material.008']} />
      <mesh geometry={nodes.Object_12.geometry} material={materials['Material.009']} />
      <mesh geometry={nodes.Object_13.geometry} material={materials['Material.013']} />
      <mesh geometry={nodes.Object_14.geometry} material={materials['Material.038']} />
      <mesh geometry={nodes.Object_15.geometry} material={materials['Material.036']} />
      <mesh geometry={nodes.Object_16.geometry} material={materials['Material.039']} />
      <mesh geometry={nodes.Object_17.geometry} material={materials['Material.040']} />
    </group>
  )
}

useGLTF.preload('/laptop.gltf')
